.feedBtn {
  font-size: 16px;
  width: 100%;
}

.btn {
  &:focus,
  &:focus-visible {
    border: none !important ;
    background: transparent !important;
  }
}

.upBtn1 {
  background-color: #4caf50;
  box-shadow: 0 9px #999;
  &:hover {
    background-color: #3e8e41;
  }
}

.upBtn2 {
  background-color: red;

  box-shadow: 0 9px #999;

  &:hover {
    background-color: red;
  }
}

.animate {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 575px) {
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
}
