// stylelint-disable declaration-no-important

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .float#{$infix}-left  { 
      @if($rtl==false){
        float: left !important; 
      }
      @if($rtl==true){
        float: right !important; 
      }
    }
    .float#{$infix}-right { 
      @if($rtl==false){
        float: right !important; 
      }
      @if($rtl==true){
        float: left !important; 
      }
    }
    .float#{$infix}-none  { float: none !important; }
  }
}
