.feedBtn {
  font-size: 16px;
}


.upBtn1 {
  background-color: #4caf50;
  box-shadow: 0 9px #999;
  &:hover {
    background-color: #3e8e41;
  }
}

.upBtn2 {
  background-color: red;

  box-shadow: 0 9px #999;

  &:hover {
    background-color: red;
  }
}

.animate {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.tableLastHead {
  padding: 0px !important;
}
.tableItemId {
  padding: 16px 8px;
  padding-left: 8px !important;
}

.postTableHeading {
  th {
    span {
      font-size: 12px;
    }
    .fontSize10 {
      font-size: 10px;
    }
  }
  td {
    span {
      font-size: 12px;
    }
    .fontSize10 {
      font-size: 10px;
    }
  }
}

.fixedWidthCell {
  max-width: 350px; /* Adjust the width as needed */
}

.replyBtnGreen {
  cursor: pointer !important;
  &:hover {
    font-size: 14px !important;
    color: green;
    font-weight: bold;
  }
}

.replyBtnRed {
  cursor: not-allowed !important;
  &:hover {
    font-size: 14px !important;
    color: red;
    font-weight: bold;
  }
}

.transform {
  transform: translate3d(-341px, 0px, 0px) !important;
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 575px) {
  .feedBtn {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
}
