.editorDiv>div>div>div>div:nth-child(2) {
  height: 50vh !important;
}

.greenColor {
  color: #1ee0ac !important;
}

.yellowColor {
  color: #f4bd0e !important;
}

.redColor {
  color: #e85347 !important;
}

.disableDropdown {
  cursor: not-allowed !important;
}

.jobDetailsInlineBlock {
  display: inline-block;
}

.changeStatusDrop {
  color: #d56c33 !important;
  border-color: #d56c33 !important;

  &:hover {
    background-color: #d56c33 !important;
    color: white !important;
  }
}

.inputFile {
  input {
    display: none;
  }

  background-color: #024e52;
  color: #fff;
  padding: 8px 40px;
  border-radius: 22px;
}

.video {
  width: 250px;
}

.removeFile {
  display: inline-block;
  transform: translateY(2px);
}