.cont {
  border: 1px solid #ccc;
  border-radius: 3px;
}

.audioProgress {
  background-color: #f0f0f0;
  border-radius: 7px;
  width: 200px;
  display: flex;
  padding: 10px;
  align-items: center;
  margin-right: 4px;
}

.progress {
  height: 4px !important;
  width: 100%;
  background-color: #dad9d9;
}

.progressBar {
  background-color: #909090 !important;
  transition: width 0.2s linear;
}

.audioPlayBtn {
  background-color: #ffdecd;
  border: 0;
  color: #333;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  padding: 4px 24px;
  white-space: nowrap;
  outline: none !important;

  svg {
    color: #d56c32
  }
}

.duration {
  font-size: 10px;
  color: #000;

  span {
    color: #999
  }
}