.jobPreview__container {
  position: relative;

  .preview__mobile {
    border: 8px solid #aaa7a7;
    border-radius: 20px;

    width: 90%;
    margin: auto;
    overflow: hidden;
    margin-bottom: 20px;

    .preview__top {
      background: #00494d;
      color: #fff;

      .logo {
        color: #d56c33;
        text-align: center;
        margin-right: 3px;
        font-size: 10px;
      }

      .preview__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 5px 10px;

        > div:last-child .nav__logo {
          font-size: 12px;
          margin: 5px 3px;
        }
      }

      .company {
        display: flex;
        margin: 8px;

        .company__logo {
          img {
            width: 32px;
            height: 32px;
            margin: 0 5px 0 2px;
          }
        }

        .company__details {
          font-size: 8px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          span:last-child {
            font-size: 10px;
          }
        }
      }

      .location {
        font-size: 8px;
        margin: 10px 15px;

        > div {
          margin: 2px 0;
        }
      }

      .data {
        font-size: 6px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 5px;
        padding-bottom: 10px;

        > div {
          border: 1px solid #066569;
          background: #066569;
          border-radius: 25px;
          padding: 3px;
        }
      }
    }

    .preview__bottom {
      margin: 0;
      padding: 0;

      .job__heading {
        border: 1px solid #f2f6f6;
        font-size: 10px;
        font-weight: 700;
        padding: 3px 8px;
        background: #f2f6f6;
        color: #066569;
      }

      .job__details {
        .req__details {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-evenly;
          font-size: 8px;
          font-weight: 600;
          padding: 4px 0px;

          .logo {
            color: #d56c33;
            margin-top: -2px;
            margin-right: 3px;
          }
        }

        .description {
          font-size: 16px;

          // min-height: 10vh;
          max-height: 25vh;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 5px;
            border-radius: 25px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: transparent;
            border: 1px solid #e5eded;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #004e52;
            border-radius: 100px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          p {
            font-weight: 500;
            padding: 5px;
            font-size: 10px;
            margin: 0px;
            // margin-top: -6rem;
            // scale: 0.85 0.85;
            // margin: -0.5rem 0 -1rem 0;
          }

          button {
            background: transparent;
            border: none;
            outline: none;
            font-size: 10px;
            color: #d56c33;
            margin: 0 5px;
          }
        }

        hr {
          margin: 5px 10px;
          opacity: 0.15;
        }

        .job_timing {
          font-size: 10px;
          margin: 5px;

          .heading {
            font-size: 12px;
            color: #066569;
            font-weight: 700;
            margin-left: 10px;

            .logo {
              margin-top: -3px;
              margin-right: 3px;
            }
          }
          p {
            margin: 0;
            margin-left: 25px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .preview__mobile1 {
    height: 70vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 25px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 1px solid #e5eded;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #555;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .volume_up,
  .volume_down,
  .lock {
    border: 1px solid #aaa7a7;
    width: 8px;
    height: 50px;
    background: #aaa7a7;
    position: absolute;
  }

  .volume_up {
    top: 11%;
    left: 3%;
    border-radius: 15px 0 0 15px;
  }

  .volume_down {
    top: 22%;
    left: 3%;
    border-radius: 15px 0 0 15px;
  }

  .lock {
    height: 70px;
    top: 15%;
    right: 3%;
    border-radius: 0 15px 15px 0;
  }
}
