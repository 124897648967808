.modalBody {
    label {
        margin-right: 10px;
        text-align: left;
        padding-top: 6px;
        min-width: 110px;
        font-weight: bold;
        color: black;
    }

    input {
        min-height: 30px;
        border-radius: 10px;
        text-align: center;
    }
}