// Shadow on card 
.has-shadow {
    &.bg-lighter {
        .card, .sp-pdl-item, .invoice-wrap, .accordion {
            box-shadow: 0 0 8px -3px rgba($shadow-color-light, 0.15);
        }
    }
    .card, .sp-pdl-item, .invoice-wrap, .accordion {
        box-shadow: 0 0 6px -2px rgba($shadow-color-light, 0.2);
    }
    .data-head, .data-item {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .card .data-head:not(.is-plain) {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        @if($rtl==false){
            padding-left: 0.75rem;
        }
        @if($rtl==true){
            padding-right: 0.75rem;
        }
    }
    .nav-tabs {
        box-shadow: 0 2px 0 -1px rgba($shadow-color-light, 0.06);
    }
    .nk-header {
        box-shadow: 0 3px 0 -1px rgba($shadow-color-light, 0.04);
    }
}

.nio-version {
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.02em;
    position: absolute;
    right: -1px;
    bottom: -3px;
}

.code {
    &-tag {
        color: $purple;
    }
    &-class {
        color: $pink;
    }
    &-fnc {
        color: $cyan;
    }
}

.nk-switch-icon{
    color: lighten($base-light, 5%);
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
        font-size: 1.5rem;
    }
    .icon-sm{
        font-size: 1.25rem;
    }
    &:hover{
        color: $base-text;
    }
    &.active{
        color: $accent-color;
    }
}

//Dark mode switch
.dark-switch{
    position: relative;
    &:before,&:after{
        position: absolute;
        content: "";
        transform: translateY(-50%);
        border-radius: 1.5rem;
        display: block;
        top: 50%;
    }
    &:before{
        @if($rtl==false){
            right: 0;
        }
        @if($rtl==true){
            left: 0;
        }
        width: 3rem;
        height: 1.5rem;
        background-color: $custom-control-indicator-bg;
        border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    }
    &:after{
        height: 1rem;
        width: 1rem;
        @if($rtl==false){
            right: calc(2rem - #{$custom-control-indicator-border-width} - .125rem);
        }
        @if($rtl==true){
            left: calc(2rem - #{$custom-control-indicator-border-width} - .125rem);
        }
        background-color: $custom-control-indicator-border-color;
    }
    &.active{
        &:before{
            background-color: $accent-color;
            border-color: $accent-color;
        }
        &:after{
            @if($rtl==false){
                right: calc(#{$custom-control-indicator-border-width} + .125rem);
            }
            @if($rtl==true){
                left: calc(#{$custom-control-indicator-border-width} + .125rem);
            }
            background-color: $white;
        }
    }
}

.is-scrollable {
    overflow: auto;
}
@if($rtl==true){
    .ni {
        &[class*="left"],&[class*="right"], &[class*="back-ios"], &[class*="forward-ios"] {
        transform: scale(-1);
            .btn > span + & {
                @if($rtl==false){
                    padding-left: 0;
                    padding-right: 8px;
                }
                @if($rtl==true){
                    padding-right: 0;
                    padding-left: 8px;
                }
            }
        }
    }
}