.inactivePostDropDown {
  background-color: transparent !important;
}

.disableDropDown {
  color: #dddddd !important;
  font-weight: 500 !important;
}

.greenColor {
  color: #1ee0ac !important;
}

.yellowColor {
  color: #f4bd0e !important;
}

.redColor {
  color: #e85347 !important;
}

.lightRed {
  color: #d80808;
}

.postTableHeading th {
  vertical-align: middle !important;
}

.postTablePara td {
  vertical-align: middle !important;
}

.minWidth150 {
  min-width: 150px;
}

.minWidth100 {
  min-width: 100px;
}

.tableScroll {
  & > div {
    &::-webkit-scrollbar {
      width: 5px;
      height: 0px;
      border-radius: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 1px solid #e5eded;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #004e52;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
