.container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 100px;
  }
  
  .sectionContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .box {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
  
  .sectionTitle {
    margin-bottom: 15px;
    font-size: 1.2em;
    color: #333;
  }
  
  .inputSection {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .inputField {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .addButton {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .addButton:hover {
    background-color: #0056b3;
  }
  
  .list {
    max-height: 200px;
    overflow-y: auto;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  
  .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    margin-bottom: 8px;
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  
  .removeButton {
    cursor: pointer;
    color: #ff4d4d;
    font-weight: bold;
    background: none;
    border: none;
  }
  
  .removeButton:hover {
    color: darkred;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .saveButton, .updateButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  .saveButton {
    background-color: #28a745;
  }
  
  .saveButton:hover {
    background-color: #218838;
  }
  
  .updateButton {
    background-color: #ffc107;
  }
  
  .updateButton:hover {
    background-color: #e0a800;
  }
  